import React,{Component} from 'react';
import { Tabs , Tab } from 'react-bootstrap';
// import './demo.scss';

const newDemoList = [
    { imageUrl: 'd-1.jpg', title: 'Demo 1', url: 'g-1'},
    { imageUrl: 'd-2.jpg', title: 'Demo 2',   url: 'g-2'},
    { imageUrl: 'd-3.jpg', title: 'Demo 3',   url: 'g-3'},
    { imageUrl: 'd-4.jpg', title: 'Demo 4',   url: 'g-4'},
    { imageUrl: 'd-5.jpg', title: 'Demo 5',   url: 'g-5'},
    { imageUrl: 'd-29.jpg', title: 'Demo 6',   url: 'g-6'}
]

const shopList = [
    { imageUrl: 'd-7.jpg', title: 'Shop 1',    url: 'shop-1'},
    { imageUrl: 'd-8.jpg', title: 'Shop 2',   url: 'shop-2'},
    { imageUrl: 'd-9.jpg', title: 'Shop 3',   url: 'shop-3'},
    { imageUrl: 'd-10.jpg', title: 'Shop 4',   url: 'shop-4'},
    { imageUrl: 'd-11.jpg', title: 'Shop 5',   url: 'shop-5'},
]
const productList = [
    { imageUrl: 'd-13.jpg', title: 'Single Product 1', url: 'single-product-1'},
    { imageUrl: 'd-14.jpg', title: 'Single Product 2', url: 'single-product-2'},
    { imageUrl: 'd-15.jpg', title: 'Single Product 3', url: 'single-product-3'},
    { imageUrl: 'd-16.jpg', title: 'Single Product 4', url: 'single-product-4'},
]

const pageList = [
    { imageUrl: 'd-17.jpg', title: 'About', url: 'about'},
    { imageUrl: 'd-18.jpg', title: 'Contact', url: 'contact'},
    { imageUrl: 'd-19.jpg', title: 'Blog', url: 'blog'},
    { imageUrl: 'd-20.jpg', title: 'Single  log',    url: 'blog'},
    { imageUrl: 'd-21.jpg', title: 'FAQ', url: 'faq'},
    { imageUrl: 'd-22.jpg', title: '404', url: 'notfound'},
]
const dashboardList = [
    { imageUrl: 'd-23.jpg', title: 'Dashboard', url: 'dashboard'},
    { imageUrl: 'd-24.jpg', title: 'Address', url: 'address'},
    { imageUrl: 'd-25.jpg', title: 'Order', url: 'orders'},
    { imageUrl: 'd-26.jpg', title: 'Coupon', url: 'coupon'},
    { imageUrl: 'd-27.jpg', title: 'Payment', url: 'payment'},
    { imageUrl: 'd-28.jpg', title: 'Notification', url: 'notification'},
]

const featureList = [
    { imageUrl: 'f-1.png', title: 'Bootstrap', des: 'Choose among many ready to get your business running in no time!'},
    { imageUrl: 'f-8.png', title: 'Cross', des: 'Use our unique pre-made elements to make your site stand out.'},
    { imageUrl: 'f-7.png', title: 'Clean', des: 'SaasLand has a super easy way toimport the demo with One moment.'},
    { imageUrl: 'f-9.png', title: 'Solid', des: 'Get the most popular solutions on themarket for working with for free!'},
    { imageUrl: 'f-6.png', title: 'Blazing', des: 'The code is always optimized for bestperformance on all.'},
    { imageUrl: 'f-2.png', title: 'Included', des: 'The documentation file covers all theInformation needed to install and use this.'},
    { imageUrl: 'f-4.png', title: 'Responsive', des: 'Responsive to makeyour website look beautiful in all devices.'},
    { imageUrl: 'f-5.png', title: 'Fast', des: 'Stuck? Need help? Visit our supportportal to find FAQ and a way to contact us.'},
    { imageUrl: 'f-3.png', title: 'Documentation', des: 'The documentation file covers all theInformation needed to install and use this.'},
]


class Demo extends Component {
    render() {
        return (
            <div className="demo-style">

                <div className="demo-header posa top-0 left-0 w-100 py-lg-4 py-3 z-index-10">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-xs-12">
                                <a href="/"><img src="assets/images/logo-white.png" alt="logo" className="logo" /></a>
                                <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="col-lg-6 col-md-12 col-xs-12">

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav list-inline">
                                        <li className="nav-item list-inline-item">
                                        <a className="active" href="#banner">Home</a>
                                        </li>
                                        <li className="nav-item list-inline-item">
                                        <a href="#feature">Features</a>
                                        </li>
                                        <li className="nav-item list-inline-item">
                                        <a href="#demodiv">Demo</a>
                                        </li>
                                        <li className="nav-item list-inline-item">
                                        <a href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                            <div className="col-lg-3 d-lg-block d-none text-end col-md-6 col-xs-6">
                                <a href="/" className="bg-success btn btn-lg p-2 lh-28 d-inline-block rounded-25 font-xsssss fw-700 ls-3 text-white w-150">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="demo-banner" id="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 text-center mt-lg-5 content-text">
                                <h1 className="text-white">Online Grocery Ordering HTML Template</h1>
                                <p className="fw-500 text-grey-500 pt-2 pb-2">Orgomart is simply the best choice for your new website. Your search for the best solution is over, get your own copy and join thousands of happy customers.</p>
                                <a href="#demodiv" className="bg-success btn btn-lg p-3 rounded-6 font-xsssss fw-700 ls-3 text-white w-175 text-uppercase">Explore demo</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="demo-feature pb-5" id="feature">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center mb-5 pt-lg-5">
                                <h2 className="text-grey-900 fw-700">Explore Feature</h2>
                                <p className="text-grey-600 fw-500">Orgomart powers thousands of apps at some of the smartest companies around the world. Be a part of professional community.</p>
                            </div>
                        </div>
                        <div className="row">
                            
                                {featureList.map((value , index) => (
                                    // Start Single Demo 
                                <div key={index} className="col-lg-4 col-md-6 text-center mb-lg-5 mb-3 px-lg-3">
                                    <div className="feature_section_wrapper">
                                        <img src={`assets/images/demo/${value.imageUrl}`} alt="icon" className="mb-4 w-60" />
                                        <h5 className="title text-grey-900"> {value.title}</h5>
                                        <p className="description fw-500 text-grey-600 font-xsss">{value.des}</p>
                                    </div>
                                </div>

                                ))}
                        </div>
                    </div>
                </div>




                <div className="demo-link pt-5 pb-5 bg-lightgrey" id="demodiv">
                    <div className="container-fluid max-container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center pt-lg-5">
                                <span className="text-success text-uppercase fw-700 ls-3 font-xsssss p-2 rounded-6 alert-success">PRE-BUILT DEMOS</span>
                                <h2 className="fw-700 mt-4 mb-4">Choose out of the pre-built demos Orgomart</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <Tabs defaultActiveKey="demo" id="uncontrolled-tab-example" className="mb-3 list-inline-center d-block text-center border-0">
                                <Tab eventKey="demo" title="Demo" className="list-inline-item">
                                    <div className="row mt-5">
                                        {newDemoList.map((value , index) => (
                                            // Start Single Demo 
                                            <div key={index} className="col-lg-4 mb-lg-5 mb-4 col-md-6 demo-item px-lg-4">
                                                <div className="card p-5 pb-4 rounded-10 bg-white border-0">
                                                <a href={`/${value.url} `}><img src={`assets/images/demo/${value.imageUrl}`} alt="demo-i" className="w-100 rounded-6 ovh" /></a>
                                                    <h4 className="text-grey-900 text-center mt-lg-4 pt-2 mt-3 fw-700 font-xsss">{value.title}</h4>
                                                </div>
                                            </div>                               
                                            // End Single Demo
                                        ))}
                                    </div>
                                </Tab>
                                <Tab eventKey="shop" title="Shop" className="list-inline-item">
                                    <div className="row mt-5">
                                        {shopList.map((value , index) => (
                                            // Start Single Demo 
                                            <div key={index} className="col-lg-4 mb-lg-5 mb-4 col-md-6 demo-item px-lg-4">
                                                <div className="card p-5 pb-4 rounded-10 bg-white border-0">
                                                <a href={`/${value.url} `}><img src={`assets/images/demo/${value.imageUrl}`} alt="demo-i" className="w-100 rounded-6 ovh" /></a>
                                                    <h4 className="text-grey-900 text-center mt-lg-4 pt-2 mt-3 fw-700 font-xsss">{value.title}</h4>
                                                </div>
                                            </div>                               
                                            // End Single Demo
                                        ))}
                                    </div>
                                </Tab>
                                <Tab eventKey="product" title="Product" className="list-inline-item">
                                    <div className="row mt-5">
                                        {productList.map((value , index) => (
                                            // Start Single Demo 
                                            <div key={index} className="col-lg-4 mb-lg-5 mb-4 col-md-6 demo-item px-lg-4">
                                                <div className="card p-5 pb-4 rounded-10 bg-white border-0">
                                                <a href={`/${value.url} `}><img src={`assets/images/demo/${value.imageUrl}`} alt="demo-i" className="w-100 rounded-6 ovh" /></a>
                                                    <h4 className="text-grey-900 text-center mt-lg-4 pt-2 mt-3 fw-700 font-xsss">{value.title}</h4>
                                                </div>
                                            </div>                               
                                            // End Single Demo
                                        ))}
                                    </div>
                                </Tab>
                                <Tab eventKey="page" title="Page" className="list-inline-item">
                                    <div className="row mt-5">
                                        {pageList.map((value , index) => (
                                            // Start Single Demo 
                                            <div key={index} className="col-lg-4 mb-lg-5 mb-4 col-md-6 demo-item px-lg-4">
                                                <div className="card p-5 pb-4 rounded-10 bg-white border-0">
                                                <a href={`/${value.url} `}><img src={`assets/images/demo/${value.imageUrl}`} alt="demo-i" className="w-100 rounded-6 ovh" /></a>
                                                    <h4 className="text-grey-900 text-center mt-lg-4 pt-2 mt-3 fw-700 font-xsss">{value.title}</h4>
                                                </div>
                                            </div>                               
                                            // End Single Demo
                                        ))}
                                    </div>
                                </Tab>
                                <Tab eventKey="dashboard" title="Dashboard" className="list-inline-item">
                                    <div className="row mt-5">
                                        {dashboardList.map((value , index) => (
                                            // Start Single Demo 
                                            <div key={index} className="col-lg-4 mb-lg-5 mb-4 col-md-6 demo-item px-lg-4">
                                                <div className="card p-5 pb-4 rounded-10 bg-white border-0">
                                                <a href={`/${value.url} `}><img src={`assets/images/demo/${value.imageUrl}`} alt="demo-i" className="w-100 rounded-6 ovh" /></a>
                                                    <h4 className="text-grey-900 text-center mt-lg-4 pt-2 mt-3 fw-700 font-xsss">{value.title}</h4>
                                                </div>
                                            </div>                               
                                            // End Single Demo
                                        ))}
                                    </div>
                                </Tab>
                            </Tabs>
                            <div className="col-sm-12 mt-5"></div>
                            
                            
                        </div>
                    </div>
                </div>

                <div className="bg-black pt-5 pb-5" id="contact">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center pb-lg-4">
                                <h2 className="text-white mt-4 mb-3 fw-700">Beautiful designs to get you started</h2>
                                <p className="text-grey-500 me-lg-5 ms-lg-5">Create a really awesome website, choose the version that will suit your requirements in a best way.</p>
                                <div className="col-sm-12 text-center mt-4"><a href="/" className="bg-success btn btn-lg p-3 rounded-6 font-xsssss fw-700 ls-3 text-white w-175 text-uppercase">Purchase Now</a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Demo;
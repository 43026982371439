import React,{Component} from 'react';

class Addbannerone extends Component {
    render() {
        return (
            <div className="card border-0 banner-wrap bg-image-cover bg-image-center" style={{backgroundImage: `url("assets/images/bg-grocery-2.jpg")`}}>
                <div className="slide-content style4 text-center w-100">
                    <span className="text-current">All natural products</span>
                    <h2 className="text-grey-900"><b className="d-block">SUMMER DISCOUNT </b>of the week</h2>    
                    <div className="clearfix"></div>
                    <a href="/" className="btn-lg rounded-25 btn bg-current">SHOP NOW</a>
                </div>
            </div>
        );
    }
}

export default Addbannerone;